<template>
  <div class="article">
    <p class="article-title">{{ getArticle.title }}</p>

    <img
      v-if="!getArticle.isTwo"
      :src="$getImgUrl(`assets/2-3/${getArticle.title}.png`)"
      alt=""
    />
    <div v-else style="display: flex">
      <img :src="$getImgUrl(`assets/2-3/${getArticle.title}.png`)" alt="" />
      <img :src="$getImgUrl(`assets/2-3/${getArticle.title}1.png`)" alt="" />
    </div>

    <p class="article-content">
      {{ getArticle.content }}
    </p>
    <!-- 音频 -->
  </div>
</template>
<script>
import { article } from "./list";
export default {
  name: "article",
  components: {},
  data() {
    return {};
  },
  computed: {
    getArticle() {
      return article.find((n) => n.id == this.$route.query.id) || {};
    },
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
  
<style lang="less" scoped>
.article {
  img {
    height: 150px;
    object-fit: contain;
  }
  height: 100vh;
  overflow-y: auto;
  background: #ffffff;
  padding-bottom: 80px;
  .article-title {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 15px;
  }
  .article-content {
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 10px 15px;
    color: #8e8e8e;
    line-height: 1.8;
  }
}
.audio {
  width: 345px;
  height: 52px;
  margin: auto;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
</style>